import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// CSS
import 'styles/Producten.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements/Elements'
import { MiddleText } from 'components/Texts'
import BrandSlider from 'components/Brands/BrandSlider'
import Products from 'components/Products/Products'
import ProductSlider from '../components/Products/ProductSlider'

function Producten() {

  const {
    producten: {
      acf: {
        banner_image: { localFile: { childImageSharp: { fluid: bannerImage } } },
        banner_1: banner1,
        title_blok: titleBlok,
        content_1: content1
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`
    {
      producten: wordpressPage(wordpress_id: {eq: 19}) {
        acf {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          banner_1 {
            button_text
            text
          }
          title_blok {
            sub_title
            title
          }
          content_1
        },
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="producten">
        <div className="producten-top">
          <div className="row position-relative">
            <div className="image-container col-12">
              <BackgroundImage fluid={bannerImage} />
              <div className="grey-empty-banner" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex">
                  <div className="image-text-container w-100 pt-4">
                    <h1 className="d-none">{titleBlok.title}</h1>
                    <h2 className="font-size-xl font-weight-xl image-text-container-title color-text-main px-3 px-lg-0 m-0">{titleBlok.title}</h2>
                    <p className="font-size-sm mb-4 font-weight-xl image-text-container-subtitle color-text-contrast px-3 px-lg-0 m-0">{titleBlok.sub_title}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container container-products">
              <div className="product-row mb-4 d-flex justify-content-between">
                <Products row />
              </div>
            </div>
          </div>
        </div>
        <MiddleText content={content1} className="px-lg-5 px-4" />
        <ProductSlider className="py-5 container position-relative" />
        <BannerCustom to="/showroomafspraak-maken" className="color-background-main" bannerText={banner1.text} bannerButtonText={banner1.button_text} />
        <BrandSlider title="Onze Merken" merken={false} className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default Producten