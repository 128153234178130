/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import CustomLink from 'components/CustomLink'

// CSS
import './ProductSlider.scss'


// Third Party
import _ from 'lodash'
import Slider from 'react-slick'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;

  position: absolute !important;
`


const Arrow = styled.img`
  width: 28px !important;
`


const ProductSlider = ({ className }) => {
  const {
    allWordpressWpSoorten: {
      edges: soorten
    }
  } = useStaticQuery(graphql`
  {
    allWordpressWpSoorten {
      edges {
        node {
          name
          description
          slug
          acf {
            image {
              localFile{
                publicURL
              }
            }
            example_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 850) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)

  return (
    <div className={`product-slider ${className && `${className}`}`}>
      <Slider
        dots
        infinite
        speed={500}
        slidesToShow={2}
        slidesToScroll={2}
        autoplay
        autoplaySpeed={6000}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]}
      >
        {_.map(soorten, (s, index) => (
          <CustomLink to={`/producten/${s.node.slug}/${s.node.slug}`}>
            <div key={index} className="slide-item-wrapper d-flex align-items-end position-relative">
              <StyledBackgroundImage fluid={s.node.acf.example_image.localFile.childImageSharp.fluid} />
              <div className="image-text-container d-flex flex-wrap">
                <h2 className="d-flex uppercase pl-3 pr-3 justify-content-evenly font-weight-xl font-size-xl color-text-light">
                  {s.node.name}
                  <Arrow alt="arrow" src={orangeArrow} />
                </h2>
                <div className="preview-text font-size-xm color-text-main">
                  <p className="px-2 pt-4">{s.node.description}</p>
                </div>
              </div>
            </div>
          </CustomLink>
        ))}
      </Slider>
      <div className="gradient position-absolute w-100 h-100" />
    </div>
  )
}

export default ProductSlider